.container {
  display: flex;
  height: 100%;
  flex-direction: column;
  overflow-y: auto;
}

.titleBar {
  background: var(--primary-bar-color);
  margin-bottom: var(--primary-margin);
  width: 100%;
  height: var(--topbar-height);
  padding: var(--primary-margin-m) var(--primary-margin);
  font-size: var(--info-font-size);
  border-radius: var(--primary-border-radius);
}

.infoBar {
  display: flex;
  flex-direction: row;
  background: var(--primary-bar-color);
  margin-bottom: var(--primary-margin);
  width: 100%;
  height: var(--info-bar-height);
  padding: var(--primary-margin);
  font-size: var(--info-font-size);
  border-radius: var(--primary-border-radius);
}

.actions {
  display: flex;
  flex-direction: row;
  gap: var(--primary-margin);
}

.avatar {
  width: var(--avatar-size);
  height: var(--avatar-size);
  border-radius: var(--avatar-border-radius);
  background-color: var(--secondary-color-c3);
}

.infoGroup {
  display: flex;
  flex-direction: column;
  margin: 0 10% 0 var(--primary-margin);
}

.nameText {
  margin-top: var(--primary-margin-half);
  font-size: var(--font-size-l2);
  line-height: var(--primary-panel-font-size);
}

.button {
  width: var(--primary-button-width);
  height: var(--primary-button-height);
  margin-top: var(--primary-panel-font-size);
  background: var(--primary-icon-selected-color);
  border-radius: var(--primary-border-radius);
}

.infoText {
  margin: var(--primary-margin-half) 0 var(--primary-margin-l) 0;
  font-size: var(--font-size-l4);
  line-height: var(--info-font-size);
  color: var(--primary-intro-color);
}
