.container {
  display: flex;
  flex-direction: column;
  background: var(--primary-panel-bg-color);
  height: var(--primary-listitem-height);
  border-radius: var(--primary-border-radius);
  cursor: pointer;
}

.preview {
  width: 100%;
  height: var(--primary-video-height);
  border-radius: var(--primary-border-radius);
  margin: var(--primary-margin)
}

.itemImgWrap {
  position: relative;
  box-sizing: border-box;
  width: calc(100% - var(--primary-margin-x2)) !important;
  height: var(--primary-video-innerwrap-height) !important;
  margin-bottom: var(--primary-border-radius);
  border-radius: var(--primary-border-radius);
  background-color: var(--primary-wrap-color);
}

.meta {
  height: 100%;
  cursor: pointer;
  padding: 0 var(--primary-margin);
  background-color: var(--primary-panel-bg-color);
  border-radius: var(--primary-border-radius);
}

.title {
  font-size: var(--topbar-title-font-size);
  margin-top: var(--secondary-border-radius);
  white-space: nowrap;
  text-overflow: ellipsis;
}

.uploadtime {
  font-size: var(--primary-meta-font-size);
  color: var(--tertiary-button-color);
  margin-top: var(--primary-margin-half);
}

.status {
  display: flex;
  justify-content: space-between;
  margin-top: var(--primary-margin-half);
}

.info {
  color: var(--primary-info-color);
}

.succeed {
  color: var(--primary-succeed-color);
}

.succeedIcon {
  width: var(--workitem-icon-size);
  height: var(--workitem-icon-size);
  border-radius: var(--workitem-icon-size);
  background: url('./icons/succeed.svg') no-repeat center center;
  background-size: 100% 100%;
}

.error {
  color: var(--primary-error-color);
}

.errorIcon {
  width: var(--workitem-icon-size);
  height: var(--workitem-icon-size);
  border-radius: var(--workitem-icon-size);
  background: url('./icons/error.svg') no-repeat center center;
  background-size: 100% 100%;
  cursor: pointer;
}
