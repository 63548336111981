@font-face {
    font-family: 'msyh';
    src: local('Microsoft YaHei'), local('msyh'), url('./fonts/msyh.ttc') format('truetype');
}

:root {
    --primary-font: 'msyh';

    /* font size */
    --font-size-l1: 36px;
    --font-size-l2: 24px;
    --font-size-l3: 22px;
    --font-size-l4: 20px;
    --font-size-l5: 18px;
    --font-size-l6: 16px;
    --font-size-l7: 14px;
    --font-size-l8: 12px;

    --topbar-font-size: var(--font-size-l7);
    --topbar-title-font-size: var(--font-size-l6);

    --primary-title-font-size: var(--font-size-l5);
    --primary-title-line-height: 40px;
    --secondary-title-line-height: var(--font-size-l2);

    --title-line-height-l: 47px;

    --primary-meta-font-size: var(--font-size-l8);
    --detail-font-size: var(--font-size-l7);
    --detail-line-height: 19px;
    --notify-content-line-height: 21px;

    --primary-panel-font-size: 30px;
    --info-font-size: 28px;
    --subtitle-font-size: 13px;
    --subtitle-line-height: 23px;

    --role-select-line-height: 22px;

    /* color */
    --primary-color-c1: #232943;
    --primary-color-c2: #151B29;
    --primary-color-c3: #21A2E3;
    --primary-color-c4: #3F4C79;

    --secondary-color-c1: #FF5257;
    --secondary-color-c2: #3F4C79;
    --secondary-color-c3: #FFFFFF;
    --secondary-color-c4: #E0E0E0;
    --secondary-color-c5: #C0C0C0;
    --secondary-color-c6: #2D375A;
    --secondary-color-c7: #999999;
    --secondary-color-c8: #149DDB;

    --primary-header-color: var(--primary-color-c1);
    --primary-bg-color: var(--primary-color-c2);

    --primary-button-color: var(--primary-color-c3);
    --secondary-button-color: var(--secondary-color-c1);
    --tertiary-button-color: var(--secondary-color-c7);

    --primary-table-color: var(--primary-color-c4);
    --primary-thead-color: var(--secondary-color-c2);

    --primary-hover-color: var(--primary-color-c3);
     
    --primary-font-color: var(--secondary-color-c3);
    --secondary-font-color: var(--secondary-color-c4);
    --tertiary-font-color: var(--secondary-color-c5);

    --primary-divider-color: var(--secondary-color-c6);

    --primary-icon-color: var(--secondary-color-c3);
    --primary-icon-selected-color: var(--secondary-color-c8);
    --secondary-icon-color: var(--secondary-color-c5);
    --secondary-icon-selected-color: var(--secondary-color-c3);

    --primary-bar-color: #1D243E;
    --primary-video-bg-color: #000;
    --primary-panel-bg-color: #323D67;

    --login-input-color: #000;
    --login-button-bg-color: #161C2B;

    --primary-border-color: var(--secondary-color-c5);

    --primary-indicator-color: #B0B2BB;
    --primary-wrap-color: #2A2A33;

    --primary-info-color: #00FFF4;
    --primary-succeed-color: #22E73C;
    --primary-error-color: #FF3535;

    --primary-intro-color: #D0D0D0;
    --primary-active-title-color: #F6F6F6;
    --primary-active-subtitle-color: #DDDDDD;

    --notify-content-color: #AAAAAA;

    --workbench-video-time-color: #77778c;
    --workbench-video-starttime-color: #4d88ff;
    --workbench-video-fullscreen-bg-color: #141419;

    /* size */
    --rect-width-l1: 96px;
    --rect-width-l2: 80px;
    --rect-width-l3: 72px;
    --rect-width-l4: 64px;
    --rect-width-l5: 48px;
    --rect-height-l1: 80px;
    --rect-height-l2: 64px;
    --rect-height-l3: 48px;
    --rect-height-l4: 40px;
    --rect-height-l5: 36px;
    --rect-height-l6: 32px;
    --rect-height-l7: 24px;

    --topbar-height: var(--rect-height-l1);
    --info-bar-height: 160px;

    --modal-title-height: var(--rect-height-l7);
    --modal-title-width: var(--rect-width-l3);

    --banner-icon-size: 18px;
    --toolbar-icon-size: 20px;
    --login-icon-height: 29px;
    --rest-icon-size: 16px;
    --voice-icon-size: 12px;
    --action-icon-size: 74px;
    --workitem-icon-size: 22px;
    --pass-icon-width: 15px;
    --play-icon-width: 17px;
    --play-icon-height: 22px;
    --play-circle-icon-size: 84px;
    --pause-icon-size: 24px;
    --subtitle-icon-width: 26px;
    --subtitle-icon-height: 22px;
    --fullscreen-icon-size: 16px;
    --role-voice-icon-size: 20px;

    --primary-button-width: 98px;
    --primary-button-height: var(--rect-height-l4);

    --primary-title-width: 90px;
    --primary-title-height: var(--rect-height-l4);

    --primary-input-height: var(--rect-height-l4);

    --login-input-width: 440px;
    --login-input-height: var(--rect-width-l4);

    --primary-panel-height: 120px;

    --notify-bar-height: 154px;

    --subtitle-table-th-height: 54px;

    --avatar-size: 120px;

    --large-modal-width: 854px;
    --large-modal-height: 524px;
    --medium-modal-width: 854px;
    --medium-modal-height: 416px;
    --small-modal-width: 598px;
    --small-modal-height: 396px;
    --confirm-modal-width: 415px;
    --confirm-modal-height: 208px;

    --login-panel-width: 520px;
    --login-panel-height: 568px;

    --primary-listitem-height: 345px;

    --primary-video-height: 208px;
    --primary-video-innerwrap-height: 164px;

    --modal-video-width: 385px;
    --modal-video-height: 217px;

    --primary-block-size: 183px;

    --modal-centrebox-height: 140px;

    --plus-image-size: 100px;

    --primary-input-width: 180px;
    --primary-input-width-l: 235px;

    --workbench-video-height: 280px;
    --workbench-track-height: 100px;
    --workbench-timepsan-width: 60px;

    --workbench-video-fullscreen-control-width: 648px;

    --role-select-height: 32px;

    /* minimal size */
    --minimal-width: 1280px;
    --minimal-height: 720px;

    --workbench-left-minimal-width: 400px;

    /* border */
    --primary-border-radius: 8px;
    --secondary-border-radius: 4px;
    --primary-border-size: 2px;

    --avatar-border-radius: 60px;

    /* margin */
    --primary-margin: 20px;
    --primary-margin-x2: 40px;
    --primary-margin-xl: 36px;
    --primary-margin-l: 28px;
    --primary-margin-m: 16px;
    --primary-margin-half: 10px;
    --primary-margin-s: 8px;
    --primary-margin-xs: 5px;

    --notify-text-margin: 100px;

    --topbar-nav-margin: 60px;
    --topbar-nav-title-margin: 10px;

    /* padding */
    --topbar-padding: 24px;
    --primary-padding-half: 12x;
    --primary-padding-s: 5px;
    --primary-padding-xs: 4px;
}
