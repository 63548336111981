.container {
  display: flex;
  height: 100%;
  flex-direction: column;
  overflow-y: auto;
}

.actionBar {
  display: flex;
  flex-direction: row;
  padding: var(--primary-margin) var(--primary-margin) var(--primary-margin) 0;
  background: var(--primary-bar-color);
  width: 100%;
  height: var(--rect-width-l2);
  border-radius: var(--primary-border-radius);
  color: var(--secondary-font-color);
  font-size: var(--primary-title-font-size);
  line-height: var(--primary-title-line-height);
}

.actionBar > span {
  margin: 0 var(--primary-margin-s) 0 var(--primary-margin);
}

.mailInput {
  width: var(--primary-input-width);
}

.actionArea {
  display: flex;
  align-items: center;
  margin-left: auto;
}

.resultArea {
  display: flex;
  flex-direction: column;
  padding: var(--primary-margin);
  background: var(--primary-bar-color);
  width: 100%;
  height: 100%;
  margin-top: var(--primary-margin);
  border-radius: var(--primary-border-radius);
  color: var(--secondary-font-color);
}

.actionBtn {
  width: var(--primary-button-width);
  height: var(--primary-button-height);
  border-radius: var(--primary-border-radius);
  margin-left: var(--primary-margin);
}

.actionBtn > span {
  margin: 0 auto;
}

.table {
  width: 100%;
}

.divider {
  background-color: var(--primary-divider-color);
}

.delText {
  color: var(--secondary-button-color);
}
