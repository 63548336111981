.container {
  display: flex;
  flex-grow: 1;
  height: 0;
  flex-direction: column;
}

.actionBtn, .actionBtn:active, .actionBtn:focus, .actionBtn:hover {
  width: var(--primary-button-width);
  height: var(--primary-button-height);
  border-radius: var(--primary-border-radius);
  color: var(--primary-font-color);
  background-color: var(--primary-button-color);
  border: var(--primary-button-color);
}

.paragraph {
  margin-top: var(--primary-margin);
  font-size: var(--font-size-l4);
}

