.container {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-width: var(--minimal-width);
  min-height: var(--minimal-height);
  overflow: auto;
  background: linear-gradient(-45deg, #232943, #111B3A, #0B0C13, #18224B);
	background-size: 400% 400%;
	animation: gradient 15s ease infinite;
}

@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}

.logo {
  width: 121px;
  height: 85px;
  margin: 0 auto;
  background: url(./src/logo.png) center center no-repeat;
  background-size: cover;
}

.loginPanel {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  width: var(--login-panel-width);
  height: var(--login-panel-height);
  border-radius: var(--font-size-l5);
}

.title {
  margin: var(--primary-margin-x2) var(--primary-margin-x2) 0 var(--primary-margin-x2);
  text-align: center;
  font-size: var(--font-size-l1);
  font-weight: bold;
  line-height: 50px;
  letter-spacing: 1px;
  color: var(--primary-font-color);
  line-height: var(--title-line-height-l);
  letter-spacing: 1px;
  font-style: normal;
}

.subTitle {
  margin: 24px auto 0;
  font-size: 24px;
  text-align: center;
  color: var(--primary-font-color);
  line-height: 31px;
  text-align: left;
  font-style: normal;
}

.phoneIcon {
  width: var(--toolbar-icon-size);
  height: var(--login-icon-height);
  margin: 0 var(--primary-margin-half);
  background: url(./src/phone.png) no-repeat;
}

.passIcon {
  width: var(--pass-icon-width);
  height: var(--login-icon-height);
  margin: 0 var(--primary-margin-half);
  background: url(./src/password.png) no-repeat;
}

.input {
  width: var(--login-input-width);
  height: var(--login-input-height);
  margin: var(--primary-margin-x2) var(--primary-margin-x2) 0 var(--primary-margin-x2);
  border-radius: 4px;
  font-size: var(--font-size-l2);
  text-align: center;
}

.input :global(.ant-input) {
  color: var(--primary-font-color);
  background-color: transparent;
  border: none !important;
  margin: 0 var(--primary-margin-half);
}

.button {
  margin: var(--primary-margin-x2) var(--primary-margin-x2) 0 var(--primary-margin-x2);
  height: var(--rect-width-l4);
  border-radius: var(--rect-height-l6);
  background-color: var(--login-button-bg-color);
  color: var(--primary-font-color);
  font-size: var(--info-font-size);
  text-align: center;
}
