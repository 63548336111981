.container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 0;
}

.table {
  height: calc(100% - 20px);
  width: 100%;
}

.table table {
  width: 100%;
}

.tbody {
  height: calc(100% - 40px);
  overflow-y: auto;
  overflow-x: hidden;
}

.tbody :global(.ant-input) {
  color: var(--primary-font-color);
  background-color: var(--primary-table-color);
}

.table td {
  color: var(--tertiary-font-color);
  font-size: var(--font-size-l7);
  padding: 2px;
  text-align: center;
}

.table td input {
  text-align: center;
}

.table th {
  height: var(--primary-input-height);
  background-color: var(--primary-table-color);
}

.table tr {
  border-bottom: 1px solid var(--primary-divider-color);
}

.flatText {
  display: flex;
  flex-direction: column;
}

.input {
  width: var(--primary-title-width);
  color: var(--primary-font-color);
  background-color: var(--primary-table-color);
  margin: 0;
  padding: var(--primary-padding-xs) !important;
}

.select {
  height: var(--role-select-height) !important;
  width: 150px;
  flex-grow: 1;
}

.select :global(.ant-select-selector) {
  height: var(--role-select-height) !important;
}

.select :global(.ant-select-selection-item) {
  line-height: var(--role-select-line-height) !important;
  padding: var(--primary-padding-xs) !important;
}

.voiceIcon {
  width: var(--role-voice-icon-size);
  height: var(--role-voice-icon-size);
  cursor: pointer;
  color: var(--tertiary-font-color);
  background: url('./icons/voice.svg') no-repeat center center;
}

.addIcon {
  display: flex;
  margin-top: var(--primary-margin-s) !important;
}

.icon {
  display: block;
  width: var(--toolbar-icon-size);
  height: var(--toolbar-icon-size);
  font-size: var(--toolbar-icon-size);
  background-size: contain;
}

.iconWrap {
  position: relative;
  cursor: pointer;
  margin: 0 auto;
}

.iconWrap:after {
  content: '';
  opacity: 0;
  background-color: rgba(255, 255, 255, .10);
  position: absolute;
  top: 0px;
  left: 0px;
  bottom: 0px;
  right: 0px;
  border-radius: var(--primary-border-radius);
  pointer-events: none;
}

.iconWrap:hover:after {
  opacity: 1;
  transform: scale(1.5);
}

.iconWrap:active, .iconWrap:active span {
  transform: scale(0.9);
}
