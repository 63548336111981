.container {
  display: flex;
  height: 100%;
  flex-direction: column;
  overflow-y: auto;
}

.titleBar, .resultPanel {
  width: 100%;
  height: var(--primary-panel-height);
  background: var(--primary-bar-color);
  margin-bottom: var(--primary-margin);
  padding: var(--primary-margin-m) var(--primary-margin);
  font-size: var(--primary-panel-font-size);
  line-height: var(--primary-title-line-height);
  border-radius: var(--primary-border-radius);
}

.intro {
  margin-top: var(--primary-margin);
  font-size: var(--primary-title-font-size);
  line-height: var(--secondary-title-line-height);
  color: var(--primary-intro-color);
}

.introList {
  display: flex;
  justify-content: space-around;
  gap: var(--primary-margin);
}

.introBlock {
  display: block;
  height: var(--primary-block-size);
  cursor: pointer;
  flex-grow: 1;
  background: var(--primary-bar-color);
  border-radius: var(--primary-border-radius);
  text-align: center;
}

.introList > :last-child {
  margin-right: 0;
}

.activeBlock {
  background: var(--primary-icon-selected-color);
}

.blockTitle {
  color: var(--secondary-color-c3);
  margin-top: var(--secondary-title-line-height);
  font-size: var(--secondary-title-line-height);
  line-height: var(--primary-panel-font-size);
}

.activeTitle {
  color: var(--primary-active-title-color);
}

.blockSubTitle {
  color: var(--tertiary-button-color);
  margin-top: var(--primary-margin-m);
  font-size: var(--topbar-title-font-size);
  line-height: var(--secondary-title-line-height);
}

.activeSubTitle {
  color: var(--primary-active-subtitle-color);
}

.blockPriceLine {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: var(--secondary-title-line-height);
  color: var(--primary-icon-selected-color);
}

.activePriceLine {
  color: var(--secondary-color-c3);
}

.priceSymbol {
  font-size: var(--banner-icon-size);
  line-height: var(--secondary-title-line-height);
  margin-right: var(--primary-margin-s);
}

.priceText {
  font-size: var(--font-size-l1);
  line-height: var(--primary-title-line-height);
}

.resultPanel {
  height: 100%;
  margin-top: var(--primary-margin);
  margin-bottom: 0;
  padding: var(--primary-margin);
}

.resultPanel > .table {
  margin-top: var(--primary-margin);
}
