.container {
  display: flex;
  flex-direction: column;
  background: var(--primary-panel-bg-color);
  width: 100%;
  height: 100%;
  border-radius: var(--primary-border-radius);
  cursor: pointer;
}

.centreBox {
  display: flex;
  flex-direction: column;
  height: var(--modal-centrebox-height);
  margin: auto;
}

.plusImage {
  width: var(--plus-image-size);
  height: var(--plus-image-size);
  background: url(./icons/plus.png) no-repeat;
}

.title {
  width: var(--modal-title-width);
  height: var(--modal-title-height);
  overflow-wrap: break-word;
  color: var(--primary-button-color);
  font-size: var(--primary-title-font-size);
  text-align: left;
  white-space: nowrap;
  line-height: var(--modal-title-height);
  margin: var(--primary-margin-m) auto 0 auto;
}

.detail {
  margin: 0 auto var(--primary-margin) auto;
  font-size: var(--detail-font-size);
  color: var(--tertiary-font-color);
  text-align: center;
}
