.container {
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 0;
}

.table {
  height: 100%;
  width: 100%;
}

.table table {
  width: 100%;
}

.tbody {
  height: calc(100% - var(--subtitle-table-th-height));
  overflow-y: auto;
}

.tbody :global(.ant-input) {
  color: var(--primary-font-color);
  background-color: var(--primary-table-color);
}

.select {
  width: 100%
}

.table td {
  color: var(--tertiary-font-color);
  font-size: var(--font-size-l7);
  padding: var(--primary-margin-s);
  text-align: center;
}

.table td input {
  text-align: center;
}

.table th {
  height: var(--subtitle-table-th-height);
  background-color: var(--primary-table-color);
}

.table tr {
  user-select: none;
  border-bottom: 1px solid var(--primary-divider-color);
}

.highlight {
  width: 100%;
  background: var(--primary-icon-selected-color);
}

.highlight td {
  color: var(--primary-font-color);
}

.flatText, .subtitleText {
  display: flex;
  flex-direction: column;
}

.subtitleText span, .subtitleText input{
  text-align: left !important;
  padding: var(--primary-padding-s) var(--primary-padding-half);
}

.subtitleText span:before {
  content: "\00a0";
}

.flatText input {
  height: var(--font-size-l3);
}

.previewIcon {
  display: block;
  width: var(--toolbar-icon-size);
  height: var(--toolbar-icon-size);
  font-size: var(--toolbar-icon-size);  
  background: url(./icons/preview.svg) center center no-repeat;
  background-size: contain;
}

.iconWrap {
  position: relative;
  cursor: pointer;
  width: var(--toolbar-icon-size);
  margin: 0 auto;
}

.iconWrap:after {
  content: '';
  opacity: 0;
  background-color: rgba(255, 255, 255, .10);
  position: absolute;
  top: 0px;
  left: 0px;
  bottom: 0px;
  right: 0px;
  border-radius: var(--primary-border-radius);
  pointer-events: none;
}

.iconWrap:hover:after {
  opacity: 1;
  transform: scale(1.5);
}

.iconWrap:active, .iconWrap:active span {
  transform: scale(0.9);
}

.voiceSpeedInput {
  padding: 2px;
}

span.text {
  line-height: 26px;
}

.textArea {
  padding: 2px;
  min-height: 28px !important;
  line-height: 28px !important;
}

.slider {
  width: 150px;
  height: 100%;
}
