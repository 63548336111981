.modal {
  width: var(--medium-modal-width) !important;
  height: var(--medium-modal-height) !important;
  border-radius: var(--primary-border-radius);
  background-color: var(--primary-bg-color);
}

.modal > div {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.modalBody {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
}

.left, .right, .selectGroup {
  display: flex;
  flex-direction: column;
}

.right {
  padding: 0 var(--primary-margin) var(--primary-margin) var(--primary-margin);
}

.video {
  width: var(--modal-video-width) !important;
  height: var(--modal-video-height) !important;
  background-color: var(--primary-video-bg-color) !important;
  border-radius: var(--primary-border-radius) !important;
  border: none !important;
  cursor: pointer;
}

.video :global(.ant-progress-text) {
  color: var(--primary-font-color);
}

.plusImage {
  width: var(--action-icon-size);
  height: var(--action-icon-size);
  margin: auto;
  background: url(../icons/plus.png) no-repeat;
  background-size: contain;
}

.indicator {
  margin-top: var(--primary-margin-half);
  width: var(--modal-video-width);
  text-align: center;
  font-size: var(--font-size-l7);
  color: var(--primary-indicator-color);
}

.uploadLine {
  display: flex;
  flex-direction: row;
  margin-top: var(--primary-margin-m);
}

.uploadLine > div {
  line-height: var(--secondary-title-line-height);
  font-size: var(--primary-title-font-size);
  color: var(--secondary-font-color);
}

.subtitle {
  margin-top: var(--primary-margin-s);
  color: var(--primary-icon-selected-color);
  cursor: pointer;
}

.uploadLine > div > :first-child, .selectGroup > :first-child {
  margin-bottom: var(--primary-margin);
}

.uploadBtn, .submitBtn, .deleteBtn, .cancelBtn {
  margin-left: var(--primary-margin);
  width: var(--primary-button-width);
  height: var(--primary-button-height);
  border-radius: var(--primary-border-radius);
  border: 1px solid var(--primary-button-color);
}

.uploadBtn {
  margin-left: 0;
}

.deleteBtn, .deleteBtn:hover, .deleteBtn:active, .deleteBtn:focus {
  color: var(--primary-font-color);
  background-color: var(--secondary-button-color);
  border: 1px solid var(--secondary-button-color);
}

.cancelBtn {
  color: var(--primary-button-color);
  border: 1px solid var(--primary-button-color);
  background: var(--primary-bg-color);
}

.selectGroup {
  margin-left: var(--primary-margin);
}

.selectGroup > div {
  color: var(--tertiary-font-color);
}

.modalFooter {
  display: flex;
  justify-content: flex-end;
  height: var(--primary-button-height);
  margin-top: var(--primary-margin);
}
