.modal {
  width: 415px !important;
  height: 208px !important;
  border-radius: var(--primary-border-radius);
  background-color: var(--primary-bg-color);
}

.modal > :global([class^=ant-modal-content]) {
  width: 100%;
  height: 100%;
  padding: var(--primary-margin);
  background-color: var(--primary-bg-color);
  box-shadow: none;
}

.modalBody {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  height: 100%;
  background-color: var(--primary-bg-color);
}

.icon {
  font-size: 18px;
  color: var(--secondary-button-color);
  margin-right: var(--primary-margin-s);
}

.text {
  display: flex;
  flex-direction: column;
  gap: var(--primary-margin-s);
}

.info {
  font-size: 16px;
  line-height: 21px;
  color: var(--primary-font-color);
}

.highlight {
  font-size: 13px;
  line-height: 19px;
  color: var(--secondary-button-color);
}

.modalFooter {
  display: flex;
  justify-content: flex-start;
  height: var(--primary-button-height);
  margin-top: var(--primary-margin-x2);
}

.submitBtn, .cancelBtn {
  width: var(--primary-button-width);
  height: var(--primary-button-height);
  border-radius: var(--primary-border-radius);
  border: 1px solid var(--primary-button-color);
}

.cancelBtn {
  color: var(--primary-button-color);
  border: 1px solid var(--primary-button-color);
  background: var(--primary-bg-color);  
  margin-left: var(--primary-margin);
}

