.container {
  display: flex;
  height: 100%;
  flex-direction: column;
  min-height: calc(var(--minimal-height) - var(--topbar-height) - var(--primary-margin) * 2);
  max-height: calc(100vh - var(--topbar-height) - var(--primary-margin) * 2);
  overflow-y: auto;
}

.titleBar {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: var(--rect-height-l1);
  font-size: var(--font-size-l2);
  background: var(--primary-bar-color);
  margin-bottom: var(--primary-margin);
  padding: var(--primary-margin);
  border-radius: var(--primary-border-radius);
}

.titleBar>.left {
  display: flex;
}

.titleBar>div>.title {
  display: flex;
  align-items: center;
}

.actionBtn,
.backBtn,
.actionBtn:active,
.actionBtn:focus,
.actionBtn:hover,
.backBtn:active,
.backBtn:focus,
.backBtn:hover {
  width: var(--primary-button-width);
  height: var(--primary-button-height);
  border-radius: var(--primary-border-radius);
  color: var(--primary-font-color);
  background-color: var(--primary-button-color);
  border: var(--primary-button-color);
  margin-left: var(--primary-margin);
}

.backBtn {
  background-color: var(--tertiary-button-color);
  border: var(--tertiary-button-color);
}

.workArea {
  display: flex;
  flex-direction: row;
  height: calc(100%);
  gap: var(--primary-margin);
  gap: var(--primary-margin);
  overflow: hidden;
}

.leftSection,
.mainSection,
.rightSection {
  display: flex;
  flex-direction: column;
  width: 25%;
  gap: var(--primary-margin);
}

.leftSection {
  min-width: var(--workbench-left-minimal-width);
  height: 100%;
}

.rightSection {
  width: calc(var(--primary-margin) * 3 + var(--primary-button-width) * 2);
}

.videoSection,
.charSection,
.subtitleSection,
.previewSection {
  background: var(--primary-bar-color);
  border-radius: var(--primary-border-radius);
  padding: var(--primary-margin);
}

.trackSection {
  background: var(--primary-bar-color);
  border-radius: var(--primary-border-radius);
  padding: 0 10px;
}

.previewSection {
  flex-grow: 1;
}

.previewSection {
  overflow-y: auto;
}

.videoSection {
  height: var(--workbench-video-height);
  padding: var(--primary-margin);
}

.trackSection {
  height: var(--workbench-track-height) !important;
}

.charSection {
  display: flex;
  flex-direction: column;
  flex: 1 1 0%;
}

.tab {
  color: var(--primary-font-color);
  border: 1px solid var(--primary-table-color);
  border-radius: var(--primary-border-radius);
  margin-bottom: var(--primary-margin-m);
  padding: 0;
}

.tab,
.tab>div {
  width: 100%;
  height: var(--primary-input-height);
  text-align: center;
}

.tab>div>label>div {
  font-size: var(--font-size-l6);
  line-height: var(--primary-input-height);
}

.subtitleSection {
  display: flex;
  flex-direction: column;
  padding-bottom: var(--primary-margin-h);
}

.mainContent {
  height: 0;
  display: flex;
  flex-direction: column;
  flex: 1;
  background: var(--primary-bar-color);
  border-radius: var(--primary-border-radius);
  padding: var(--primary-margin);
}

.mainSection {
  flex-grow: 1;
}

.previewSection>ul {
  list-style-type: none;
  padding: 0;
  margin-top: 20px;  
}

.previewSection>table {
  table-layout: fixed;
  margin-bottom: var(--primary-margin-x2);
}

.previewSection>table>tr {
  text-overflow: ellipsis;
}

.timeSpan {
  display: flex;
  align-items: center;
  color: var(--secondary-color-c7);
  font-size: var(--font-size-l7);
  width: var(--workbench-timepsan-width);
}

.contentSpan {
  flex-grow: 1;
  text-align: left;
}

.toolbar {
  display: flex;
  flex-direction: row;
  margin-bottom: var(--primary-margin);
}

.icon {
  display: flex;
  width: var(--toolbar-icon-size);
  height: var(--toolbar-icon-size);
  font-size: var(--toolbar-icon-size);
  background-size: contain;
}

.iconWrap {
  position: relative;
  cursor: pointer;
  height: var(--workitem-icon-size);
  margin: var(--primary-padding-s) 0 0 var(--primary-margin-x2);
}

.iconWrap:after {
  content: '';
  opacity: 0;
  background-color: rgba(255, 255, 255, .10);
  position: absolute;
  top: 0px;
  left: 0px;
  bottom: 0px;
  right: 0px;
  border-radius: var(--primary-border-radius);
  pointer-events: none;
}

.iconWrap:hover:after {
  opacity: 1;
  transform: scale(1.5);
}

.iconWrap:active,
.iconWrap:active span {
  transform: scale(0.9);
}

.editIcon {
  background: url(./icons/edit.svg) center center no-repeat;
}

.copyIcon {
  background: url(./icons/copy.svg) center center no-repeat;
}

.linkIcon {
  background: url(./icons/link.svg) center center no-repeat;
}

.cutIcon {
  background: url(./icons/cut.svg) center center no-repeat;
}

.deleteIcon {
  background: url(./icons/delete.svg) center center no-repeat;
}

.translateIcon {
  background: url(./icons/translate.svg) center center no-repeat;
}

.previewIcon {
  background: url(./subtitle-table/icons/preview.svg) center center no-repeat;
}

.popOverContainer {
  display: flex;
  gap: var(--primary-margin-half);
}

.popOverOption {
  color: var(--primary-font-color);
}

.indicator {
  margin-left: var(--primary-margin);
}

.text, .indicator {
  line-height: var(--rect-height-l6);
}

.mergeBtn {
  margin-left: var(--primary-margin-half);
}
