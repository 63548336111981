body {
  margin: 0;
  font-family: var(--primary-font);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* TODO: error message check why */
body iframe {
  display: none;
}

code {
  font-family: var(--primary-font);
}

.ant-modal {
  background: var(--primary-bg-color) ;
  border: 1px solid var(--primary-bg-color) ;
  box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.2);
  border-radius: var(--primary-border-radius);
  padding-bottom: 0;
  overflow: hidden;
}

.ant-modal-content {
  background: var(--primary-bg-color);
  border-bottom: none;
}

.ant-modal-header,
.ant-modal-body,
.ant-modal-footer {
  background: var(--primary-bg-color);
}

.ant-modal-header {
  padding: var(--primary-margin);
  border-bottom: none;
}

.ant-modal-title, .ant-modal-body {
  color: var(--primary-font-color);
}

.ant-modal-body {
  padding: var(--primary-margin);
  border-bottom: none;
}

.ant-modal-confirm-content {
  color: var(--primary-font-color) !important;
}

.ant-modal-close,
.ant-modal-close:hover {
  color: var(--primary-font-color);
}

.ant-modal-footer {
  padding: 10px 24px 24px 24px;
  border-top: none;
}

.custom-modal-foot {
  display: flex;
  justify-content: flex-end;
}

.custom-modal-foot-confirm-btn,
.custom-modal-foot-cancel-btn {
  width: 104px;
  height: 36px;
  line-height: 36px;
  text-align: center;
  font-size: 14px;
  color: #fff;
  cursor: pointer;
  border-radius: 6px;
}

.custom-modal-foot-cancel-btn {
  margin-right: 8px;
  background-color: #3F3F4D;
}

.custom-modal-foot-confirm-btn {
  background-color: #4D88FF;
}

/* 滑块 */
.ant-slider-track {
  background-color: #4D88FF !important;
}

.ant-slider-handle {
  border-color: #fff !important;
}

/* toast */
.ant-message-notice-content {
  border-radius: 22px;
  padding: 12px 25px;
  box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.05);
}

.ant-message .anticon {
  margin-right: 11px;
}

.ant-message .anticon::after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.ant-select-selector {
  height: 40px !important;
  background: var(--primary-bg-color) !important;
  border: 1px solid #B0B2BB !important;
  padding: 0px !important;
}

.ant-select-selection-item {
  padding: 10px !important;
  line-height: 14px !important;
  font-size: 14px !important;
  color: #B0B2BB !important;
}

.ant-select-item-option-content {
  color: #C0C0C0;
}

.ant-select-item-option-active, .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background-color: #3F4C79 !important;
}

.ant-select-item-option-active .ant-select-item-option-content, .ant-select-item-option-selected:not(.ant-select-item-option-disabled) .ant-select-item-option-content {
  color: var(--primary-font-color) !important;
  font-weight: 400 !important;
}

.ant-select-dropdown {
  background: var(--primary-bar-color) !important;
}

.ant-picker, .ant-input, input[type="text"] {
  color: #DDDDDD;
  border: 1px solid #B0B2BB !important;
  color: var(--primary-font-color);
  background-color: var(--primary-color-c2);
}

.ant-picker input, .ant-picker-separator {
  color: #DDDDDD !important;
}

.ant-table-thead, .ant-table-thead > tr > th {
  color: var(--primary-font-color);
  background: var(--primary-table-color);
  height: 40px;
  padding: 10px;
  border: none;
  text-align: center;
}

.ant-table-tbody > tr,  .ant-table-tbody > tr > td { 
  background: #1D243E !important;
}

.ant-table-tbody > tr > td {
  color: var(--tertiary-font-color) !important;
  border-bottom: 1px solid var(--primary-divider-color);
  text-align: center;
}

.ant-checkbox-inner {
  border-radius: 2px;
  background-color:rgba(0,0,0,0)
}

.ant-segmented-item {
  border-radius: var(--primary-border-radius);
}

.ant-segmented-thumb, .ant-segmented-item:hover, .ant-segmented-item:focus {
  color: var(--primary-font-color);
  border-radius: var(--primary-border-radius);
}

.ant-segmented-thumb {
  background: var(--primary-table-color);
}

.ant-segmented-item-selected {
  color: var(--primary-font-color);
  background: var(--primary-table-color);
}

.ant-input {
  color: #DDDDDD;
  border: 1px solid #B0B2BB !important;
}

.ant-empty-normal {
  color: var(--primary-font-color);
}

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
  background-color: #1F1F26;
}

::-webkit-scrollbar-corner {
  background-color: #1F1F26;
}

::-webkit-scrollbar-thumb{
  border: 1px solid #1F1F26;
  background-color: rgb(121, 118, 118,.3);
  border-radius: 42px;
  visibility: visible;
}

.ant-avatar > img {
  object-fit: fill;
}
