.container {
  display: flex;
  height: 100%;
  flex-direction: column;
  overflow-y: auto;
}

.intro {
  margin-top: var(--primary-margin);
  font-size: var(--primary-title-font-size);
  line-height: var(--secondary-title-line-height);
  color: var(--primary-intro-color);
}

.introList {
  display: flex;
  justify-content: space-around;
  gap: var(--primary-margin);
  margin-bottom: var(--primary-margin);
}

.introBlock {
  display: block;
  flex-grow: 1;
  background: var(--primary-bar-color);
  border-radius: var(--primary-border-radius);
  height: var(--primary-block-size);
  text-align: center;
}

.blockTitle {
  color: var(--tertiary-button-color);
  margin-top: var(--primary-margin-x2);
  font-size: var(--primary-title-font-size);
  line-height: var(--secondary-title-line-height);
}

.blockTimeLine {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: center;
  margin-top: var(--primary-margin);
  color: var(--primary-icon-selected-color) ;
}

.timeText {
  margin-left: var(--primary-margin-s);
  font-size: var(--font-size-l1);
  line-height: var(--font-size-l1);
  font-weight: normal;
}

.timeUnit {
  margin-left: var(--primary-margin-s);
  font-size: var(--detail-font-size);
  line-height: var(--detail-line-height);
  text-align: left;
}

.blockTimeLine .timeText:nth-child(1) {
  margin-left: 0;
}

.actionBar {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: var(--topbar-height);
  padding: var(--primary-margin) var(--primary-margin) var(--primary-margin) 0;
  background: var(--primary-bar-color);
  border-radius: var(--primary-border-radius);
  color: var(--secondary-font-color);
  font-size: var(--primary-title-font-size);
  line-height: var(--primary-title-line-height);
}

.actionBar > span {
  margin: 0 var(--primary-margin-s) 0 var(--primary-margin);
}

.mailInput {
  width: var(--primary-input-width);
}

.actionArea {
  display: flex;
  align-items: center;
  margin-left: auto;
}

.resultArea {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  margin-top: var(--primary-margin);
  padding: var(--primary-margin);
  background: var(--primary-bar-color);
  border-radius: var(--primary-border-radius);
  color: var(--secondary-font-color);
}

.actionBtn {
  width: var(--primary-button-width);
  height: var(--primary-button-height);
  border-radius: var(--primary-border-radius);
  margin-left: var(--primary-margin);
}

.actionBtn > span {
  margin: 0 auto;
}

.table {
  width: 100%;
}
