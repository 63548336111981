.timeLine {
  position: relative;
  height: 4px;
  border-radius: 2px;
  background-color: rgba(0, 0, 0, .2);
}

.progress {
  position: absolute;
  left: 0;
  top: 0;
  height: 4px;
  border-radius: 2px;
  background-color: #4D88FF;
}

.handler {
  position: absolute;
  top: -4px;
  width: 12px;
  height: 12px;
  background: #FFFFFF;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 50%;
  cursor: pointer;
  /* transition: left ease-in .5; */
}
