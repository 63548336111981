.topBar {
  display: flex;
  justify-content: flex-start;
  flex-shrink: 0;
  height: var(--topbar-height);
  align-items: center;
  background: var(--primary-header-color);
  padding: 0 var(--topbar-padding);
  font-size: var(--topbar-font-size);
}

.middle {
  display: flex;
  flex-direction: row;
}

.left {
  display: flex;
  align-items: center;
}

.nav {
  display: flex;
  color: var(--primary-icon-color);
  margin-left: var(--topbar-nav-margin);
  cursor: pointer;
  align-items: center;
}

.nav  svg {
  width: var(--banner-icon-size);
  font-size: var(--banner-icon-size);
  fill: currentColor;
  line-height: var(--rect-height-l7);
  margin-right: var(--primary-margin-half);
}


.avatar {
  cursor: pointer;
  background-color: var(--primary-icon-color);
  margin-right: var(--primary-margin-xs);
}

.link {
  display: flex;
  cursor: pointer;
  color: var(--primary-font-color);
}

.centreText {
  display: flex;
  cursor: pointer;
  color: var(--primary-font-color);
}

.nav:hover, .link:hover, .active {
  color: var(--primary-hover-color);
}

.pageTitle {
  display: flex;
  padding-left: var(--primary-margin-l);
  margin-right: var(--primary-margin);
  height: var(--rect-height-l7);
  align-items: center;
  cursor: pointer;
  background: url(./icons/logo.png) 0 center no-repeat;
  background-size: var(--primary-margin) var(--primary-margin);
  font-size: var(--topbar-title-font-size);
}

.right {
  display: flex;
  align-items: center;
  margin-left: auto;
}

.cornerGroup {
  display: flex;
  align-items: center;
}
