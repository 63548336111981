.container {
  display: flex;
  flex-direction: row;
  margin-bottom: var(--primary-margin);
  border-radius: var(--primary-border-radius);
  justify-content: flex-end;
}

.bot {
  justify-content: flex-start;
}

.msgBody {
  display: flex;
  flex-direction: row;
  background: #3F4C79;
  padding: var(--primary-margin-half);
  border-radius: var(--primary-border-radius);
}

.avatar {
  min-width: 32px;
  background-color: var(--primary-icon-color);
  margin-left: var(--primary-margin-half);
}

.avatarBot {
  margin-right: var(--primary-margin-half);
  margin-left: 0;
}

.loadingIcon {
  width: 30px;
  height: 14px;
  display: block;
  margin: 0 auto;
  background: url(./icons/three-dots.svg) no-repeat;
}

.text {
  display: flex;
  align-items: center;
  user-select: text;
  word-break: break-word;
  position: relative;
  line-height: 32px;
}
