.container {
  display: flex;
  height: 100%;
  flex-direction: column;
  overflow-y: auto;
}

.titleBar {
  background: var(--primary-bar-color);
  margin-bottom: var(--primary-margin);
  width: 100%;
  height: var(--topbar-height);
  padding: var(--primary-margin-m) var(--primary-margin);
  font-size: var(--info-font-size);
  border-radius: var(--primary-border-radius);
}

.notifyBar {
  background: var(--primary-bar-color);
  margin-bottom: var(--primary-margin);
  width: 100%;
  height: var(--notify-bar-height);
  padding: var(--primary-margin);
  font-size: var(--info-font-size);
  border-radius: var(--primary-border-radius);
}

.notifyTitle {
  font-size: var(--font-size-l2);
  line-height: var(--primary-panel-font-size);
  margin-bottom: var(--primary-margin-m);
}

.notifySubTitle {
  font-size: var(--primary-title-font-size);
  line-height: var(--secondary-title-line-height);
  color: var(--primary-active-subtitle-color);
  margin-bottom: var(--primary-margin);
}

.notifyContent {
  display: flex;
  flex-direction: row;
  font-size: var(--topbar-title-font-size);
  line-height: var(--notify-content-line-height);
  color: var(--notify-content-color);
}

.notifyText {
  margin-left: var(--notify-text-margin);
}
