.container {
  display: flex;
  flex-grow: 1;
  height: 0;
  flex-direction: column;
}

.row {
  width: 100%;
  color: var(--secondary-font-color);
  line-height: var(--primary-input-height);
  margin-bottom: var(--primary-margin-m);
  overflow-y: auto;
}

.title {
  width: 91px;
  color: var(--secondary-font-color);
  margin-right: var(--primary-margin);
  font-size: var(--primary-title-font-size)
}

.input {
  color: var(--login-input-color);
}

.row > div > input {
  width: 100%;
}

.right {
  display: flex;
  justify-content: flex-end;
}

.actionBtn {
  width: var(--primary-button-width);
  height: var(--primary-button-height);
  border-radius: var(--primary-border-radius);
  color: var(--primary-font-color);
  background-color: var(--primary-button-color);
  border: var(--primary-button-color);
}

.addIcon {
  display: flex;
}

.iconWrap {
  position: relative;
  cursor: pointer;
  margin: 0 auto;
}

.iconWrap:after {
  content: '';
  opacity: 0;
  background-color: rgba(255, 255, 255, .10);
  position: absolute;
  top: 0px;
  left: 0px;
  bottom: 0px;
  right: 0px;
  border-radius: var(--primary-border-radius);
  pointer-events: none;
}

.iconWrap:hover:after {
  opacity: 1;
  transform: scale(1.5);
}

.iconWrap:active, .iconWrap:active span {
  transform: scale(0.9);
}
