.container {
  background-color: var(--primary-header-color);
  color: var(--primary-font-color);
}

body { 
  overflow-y: auto; 
  overflow-x: auto;
}

.main {
  display: flex;
  flex-direction: column;
  min-width: var(--minimal-width);
  min-height: var(--minimal-height);
  height: 100vh;
  margin: 0 auto;
  overflow-y: auto;
  background: var(--primary-bg-color);
}

.content {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.spinWrapper {
  flex-grow: 1;
}

.spinWrapper > :global(.ant-spin-container) {
  position: static;
  height: 100%;
  padding: var(--primary-margin);
}

:global(.ant-spin-nested-loading > div > .ant-spin) {
  max-height: 100%;
}

:global(.ant-spin-blur) {
  position: static;
  
  background: #000000;
  opacity: 0.5;
  text-align: center;
}
