.modal {
  display: flex;
  flex-direction: column;
  width: var(--medium-modal-width) !important;
  height: var(--medium-modal-height) !important;
  border-radius: var(--primary-border-radius);
  background-color: var(--primary-bg-color);
}

.modal :global(.ant-modal-body) {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.modal > div {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.modalBody {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
}

.form {
  width: 100%;
}

.uploadLine {
  display: flex;
  flex-direction: row;
  margin-top: var(--primary-margin-m);
}

.uploadLine > div {
  line-height: var(--secondary-title-line-height);
  font-size: var(--primary-title-font-size);
  color: var(--secondary-font-color);
}

.uploadLine > div > :first-child, .selectGroup > :first-child {
  margin-bottom: var(--primary-margin);
}

.dataRow {
  margin-bottom: 20px;
}

.title {
  width: var(--primary-title-width);
  height: var(--primary-title-height);
  line-height: var(--primary-title-line-height);
}

.input {
  width: var(--primary-input-width-l);
  height: var(--primary-input-height);
  color: var(--primary-font-color);
  background-color: var(--primary-table-color);
  margin: 0;
  padding: var(--primary-margin-xs) !important;
}

.uploadBtn, .submitBtn, .deleteBtn, .cancelBtn {
  margin-left: var(--primary-margin);
  width: var(--primary-button-width);
  height: var(--primary-button-height);
  border-radius: var(--primary-border-radius);
  border: 1px solid var(--primary-button-color);
}

.uploadBtn {
  margin-left: 0;
}

.deleteBtn, .deleteBtn:hover, .deleteBtn:active, .deleteBtn:focus {
  color: var(--primary-font-color);
  background-color: var(--secondary-button-color);
  border: 1px solid var(--secondary-button-color);
}

.cancelBtn {
  color: var(--primary-button-color);
  border: 1px solid var(--primary-button-color);
  background: var(--primary-bg-color);
}

.modalFooter {
  display: flex;
  justify-content: flex-end;
  height: var(--primary-button-height);
  margin-top: var(--primary-margin);
}
