.container {
  display: flex;
  width: 100%;
  height: 100%;
  background-color: var(--primary-header-color);
  border-radius: var(--primary-border-radius);
  padding: var(--primary-margin) var(--primary-margin-half);
}

.listwrap {
  width: 100%;
  height: 100%;
}

.list {
  width: 100%;
  max-height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  margin: 0 !important;
}

.firstitem {
  display: block;
  height: var(--primary-listitem-height);
  border-radius: var(--primary-border-radius);
  flex: 0 0 40%;
  max-width: 40%;
}

.importArea {
  display: block;
  background: var(--primary-panel-bg-color);
  border-radius: var(--primary-border-radius);
  height: var(--primary-listitem-height);
  padding: var(--primary-margin);
}

.dropzone {
  width: 100%;
  height: 100%;
  border-radius: var(--primary-border-radius);
  border: var(--primary-border-size) dashed var(--primary-border-color);
}

.item {
  display: block;
  height: var(--primary-listitem-height);
  border-radius: var(--primary-border-radius);
  flex: 0 0 20%;
  max-width: 20%;
}
