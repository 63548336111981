.container {
  display: flex;
  height: calc(100% - var(--primary-input-height) - var(--primary-margin-m));
  flex-direction: column;
  overflow-y: auto;
}

.row {
  width: 100%;
  height: var(--primary-input-height);
  color: var(--secondary-font-color);
  line-height: var(--primary-input-height);
  margin-bottom: var(--primary-margin-m);
}

.title {
  width: 91px;
  color: var(--secondary-font-color);
  margin-right: var(--primary-margin);
  font-size: var(--primary-title-font-size)
}

.input {
  color: var(--login-input-color);
}

.row > div > input {
  width: 100%;
}

.right {
  justify-content: end;
}

.actionBtn {
  width: var(--primary-button-width);
  height: var(--primary-button-height);
  border-radius: var(--primary-border-radius);
  color: var(--primary-font-color);
  background-color: var(--primary-button-color);
  border: var(--primary-button-color);
}
