.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  bottom: var(--primary-margin-x2);
  left: 0;
  width: 100%;
  cursor: move;
  z-index: 300;
}

.container:hover {
  background: rgba(0, 0, 0, .6);
}

.contentWrap {
  padding: 0 var(--primary-margin);
  margin: var(--primary-margin-s) var(--primary-margin-half);
  white-space: pre-wrap;
}

.subtitle {
  color: var(--primary-font-color);
  background: rgba(0, 0, 0, .6);
  font-size: var(--subtitle-font-size);
  line-height: var(--subtitle-line-height);
  width: fit-content;
  margin: 0 auto;
}
