.videoWrap {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.videoPlayer {
  background-color: var(--primary-video-bg-color);
  border-radius: var(--primary-border-radius);
}

.totalTime {
  color: var(--workbench-video-time-color);
}

.videoWrapFullScreen {
  position: fixed;
  z-index: 999999;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  padding-right: 0;
  background-color: var(--workbench-video-fullscreen-bg-color);
}

.video_controlWrap {
  position: absolute;
  bottom: 0px;
  width: 100%;
  padding: var(--primary-margin-half);
}

.videoWrapFullScreen .video_controlWrap {
  box-sizing: border-box;
  position: absolute;
  bottom: var(--rect-height-l5);
  width: var(--workbench-video-fullscreen-control-width);
  left: 50%;
  transform: translateX(-50%);
  padding: var(--primary-padding-half);
  background: rgba(42, 42, 51, 0.8);
  border-radius: var(--primary-border-radius);
}

.video_control {
  height: var(--rect-height-l6);
  display: flex;
  background: rgba(0, 0, 0, .6);
  border-radius: var(--primary-border-radius);
  align-items: center;
  justify-content: flex-start;
  gap: var(--primary-margin);
  padding: 0 var(--primary-margin-half);
}

.videoWrapFullScreen .video_control {
  background: transparent;
  padding: var(--primary-margin-half);
}

.startTime {
  color: var(--workbench-video-starttime-color);
}

.control_btn {
  cursor: pointer;
  width: var(--rect-height-l7);
}

.action_bar {
  display: flex;
  align-items: center;
  margin-left: auto;
}

.playIcon {
  width: var(--play-icon-width);
  height: var(--play-icon-height);
  cursor: pointer;
  background: url('./icons/play.svg') no-repeat center center;
}

.playCircleIcon {
  width: var(--play-circle-icon-size);
  height: var(--play-circle-icon-size);
  cursor: pointer;
  background: url('./icons/play-circle.svg') no-repeat center center;
}

.pauseIcon {
  width: var(--pause-icon-size);
  height: var(--pause-icon-size);
  cursor: pointer;
  background: url('./icons/pause.svg') no-repeat center center;
}

.subtitleIcon {
  width: var(--subtitle-icon-width);
  height: var(--subtitle-icon-height);
  cursor: pointer;
  background: url('./icons/subtitle.png') no-repeat center center;
}

.fullScreen,
.cancelFullScreen {
  margin-right: var(--primary-margin-m);
  width: var(--fullscreen-icon-size);
  height: var(--fullscreen-icon-size);
  background: center center no-repeat;
  cursor: pointer;
}

.fullScreen {
  background-image: url(./icons/full-screen.svg);
}

.cancelFullScreen {
  background-image: url(./icons/cancel-full-screen.svg);
}

.voiceIcon {
  width: var(--pause-icon-size);
  height: var(--pause-icon-size);
  cursor: pointer;
  background: url('./icons/voice.svg') no-repeat center center;
}

.menu, .menu ul, .menu ul li {
  color: var(--primary-indicator-color);
  background-color: var(--primary-bar-color);
}

.menu :global(.ant-dropdown-menu-item-active) {
  color: var(--primary-font-color);
  background-color: var(--primary-color-c4) !important;
}
