.container {
  display: flex;
  flex-grow: 1;
  height: 0;
  flex-direction: column;
}

.chatBody {
  flex-grow: 1;
  height: 0;
  overflow-y: auto;
}

.chatInput {
  width: 100%;
  height: 168px;
  border: 1px solid var(--primary-table-color);
  border-radius: var(--primary-border-radius);
  padding: var(--primary-margin);
}

.mainInput {
  width: 100%;
  height: 100%;
  font-size: var(--primary-title-font-size);
  border: none;
  outline: none;
  resize: none;
  overflow: auto;
  background-color: transparent;
}

.sendBtn {
  position: relative;
  left: calc(100% - 100px);
  bottom: var(--primary-margin-x2);
}

.resendBtn {
  z-index: 1000;
  position: sticky;
  color: #979797 !important;
  background: var(--primary-bar-color) !important;
  left: calc(100% - 220px);
  top: calc(100% - 60px);
  border: 1px solid #979797 !important;
}

.clearBtn {
  z-index: 1000;
  position: sticky;
  background-color: #3F4C79 !important;
  left: calc(100% - 100px);
  top: calc(100% - 60px);
}

.actionBtn, .actionBtn:active, .actionBtn:focus, .actionBtn:hover {
  width: var(--primary-button-width);
  height: var(--primary-button-height);
  border-radius: var(--primary-border-radius);
  color: var(--primary-font-color);
  background-color: var(--primary-button-color);
  border: var(--primary-button-color);
}
